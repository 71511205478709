import React, {useState, useEffect} from 'react';
import styles from "../../components/innerPage.module.css"
import Link from "gatsby-link"
import {navigate} from "gatsby"
import Layout from "../../components/Investor/layout";
import SEO from "../../components/seo"
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import News_styles from "../../components/home2news.module.css"
import { graphql } from 'gatsby';
import {isNullUndefined, getMomentDate, capitalizeFirstLetter, isEmptyArray} from "../../util"
import queryString from 'query-string';
import ScrollAnimation from 'react-animate-on-scroll';
 
export const pageQueryCurrentVacanciesTemplate = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulCurrentVacanciesTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
          spaceId
          slug
          title
        }
        allContentfulCareersItemTemplate {
            edges {
              node {
                country
                jobTitle
                jobType
                location
                slug
                spaceId
                isPlaceholder
              }
            }
        }
    }
`

const CurrentVacancies = (props) => {

  let {title, slug, spaceId} = props.data.contentfulCurrentVacanciesTemplate;

  const edges = props?.data?.allContentfulCareersItemTemplate?.edges;

  let investorEdges = edges?.filter(edge => edge?.node?.spaceId === process.env.GATSBY_INVESTOR_SPACE_ID && !edge?.node?.isPlaceholder)

  return ( 
    <div>
            <Layout>
            <SEO title={`${capitalizeFirstLetter(title)} | Investor or Media Relations`} />
            <div className={styles.banner} style={{background: 'url(https://cdn.pixabay.com/photo/2016/09/23/20/54/doors-1690423_960_720.jpg)'}}>
            <div className={styles.overlay}></div>
                        <div className="container">
                           <div className="row">
                               <div className="col-12 col-sm-12 col-md-12">
                                
                               <ScrollAnimation animateIn="fadeIn"><h1 className="">{title}</h1></ScrollAnimation>
                               <ol className="custom_breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/Investor/home">Home</Link></li>
                                    <li className="breadcrumb-item"><a href="">Careers</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">{title}</li>
                                </ol>
                               </div>
                               </div>
                        </div>
            </div>
            <ScrollAnimation animateIn="fadeIn">
            <div className={styles.content}>
                    <div className="container pt-5 pb-5">
                    <div className="row">
                    <div className="col-12 col-sm-12 col-md-12"  >
                 
              {
                (!isNullUndefined(investorEdges) && investorEdges.length > 0 ) ?   <table className="table careeers">
                <thead className="thead-light">
                    <tr>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Type</th>
                    <th scope="col">Country</th>
                    <th scope="col">Location</th>
                    <th ></th>
                    </tr>
                </thead>
                <tbody>
                 
                    {investorEdges.map((item) => {
                        return (
                            <tr key={item.node.slug}>
                                <td scope="row">{item.node.jobTitle}</td>
                                <td>{item.node.jobType}</td>
                                <td>{item.node.country}</td>
                                <td>{item.node.location}</td>
                                <td><span style={{whiteSpace:'nowrap'}} onClick={() => navigate(`/Investor/Careers/${slug}/${item.node.slug}`)}>Apply now</span></td>
                            </tr>
                        )
                    })} 
                </tbody>
                </table> :  <table className="table careeers">
                <thead className="thead-light">
                    <tr>
                    <th scope="col">Job Title</th>
                    <th scope="col">Job Type</th>
                    <th scope="col">Country</th>
                    <th scope="col">Location</th>
                    <th ></th>
                    </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{border: "none"}}>
                      Nothing to show at the moment, please try again later.
                    </td>
                  </tr>
                  </tbody>
                 </table>
              }
                  </div>
                 

                </div>
              

                    </div>
                 
           </div>
         </ScrollAnimation>
         </Layout>
        </div>
    );
}
 
export default CurrentVacancies;